import React from 'react';

import styles from '../../styles/LoadingBar.module.less';

const LoadingBar = () => {
  return (
    <div className={styles.container}>
      <div className={styles.bar} />
    </div>
  );
};

export default LoadingBar;
