import React from 'react';

import LoadableRouteErrorBoundary from 'barnyard/typescript/router/LoadableRouteErrorBoundary';

import { Error500 } from 'barnyard/pages';

type RouteErrorBoundaryProps = { children: React.ReactNode };

const RouteErrorBoundary = ({ children }: RouteErrorBoundaryProps) => {
  return (
    <LoadableRouteErrorBoundary Fallback={Error500}>{children}</LoadableRouteErrorBoundary>
  );
};

export default RouteErrorBoundary;
