import './common/styles/imports.less';

import React from 'react';
import { LocationProvider, Redirect } from '@reach/router';
import { Error404 } from 'barnyard/pages';
import LoadableRouter from 'barnyard/typescript/router/LoadableRouter';
import { QueryCacheProvider } from 'barnyard/typescript/cache/QueryCacheProvider';

import LoadingBar from '~/auth/common/components/loading/LoadingBar';
import RouteErrorBoundary from '~/auth/common/components/errors/RouteErrorBoundary';

import { basepath } from './common/routes/urls';
import AccountApp from './account/routes';
import SignInApp from './signin/routes';

const AuthApp = () => {
  return (
    <LocationProvider>
      <QueryCacheProvider>
        <LoadableRouter
          basepath={basepath}
          LoadingComponent={LoadingBar}
          ErrorBoundaryComponent={RouteErrorBoundary}
        >
          <Error404 default={true} />
          <Redirect from='/' to={AccountApp.paths.full} noThrow={true} />
          <AccountApp path={AccountApp.paths.app} />
          <SignInApp path={SignInApp.paths.app} />
        </LoadableRouter>
      </QueryCacheProvider>
    </LocationProvider>
  );
};

export default AuthApp;
