import React from 'react';
import { Router, RouteComponentProps } from '@reach/router';
import { Error404 } from 'barnyard/pages';

import LoadableRoute from 'barnyard/typescript/router/LoadableRoute';

import { paths } from './urls';

const SiginApp: React.FC<RouteComponentProps> = () => {
  return (
    <Router>
      <Error404 default={true} />
      <LoadableRoute
        importStatement={() => import('../components/pages/SignInPage')}
        path={paths.signinPage}
      />
      <LoadableRoute
        importStatement={() => import('../components/SignIn')}
        path={paths.signinFormPage}
      />
      <LoadableRoute
        importStatement={() => import('../components/SsoSignIn')}
        path={paths.ssoSigninPage}
      />
    </Router>
  );
};

export default Object.assign(SiginApp, { paths });
