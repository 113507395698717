import { stringifyUrl } from 'query-string';

import { createPaths, basepath } from '~/auth/common/routes/urls';

import { PasswordResetPageQueryParams, ProfileVerifyParams, SuspiciousLoginValues } from '../types';

export const paths = createPaths('account', {
  portalPage: '/',
  accountPage: '/profile',
  passwordPage: '/password',
  passwordResetPage: '/password/reset',
  passwordSetPage: '/password/set',
  profileVerifyPage: '/verify',
  suspiciousLoginPage: '/suspicious-login',
});

export const urls = {
  getPortalPage: () => `${paths.full}`,
  getAccountPage: () => `${paths.full}${paths.accountPage}`,
  getPasswordPage: () => `${paths.full}${paths.passwordPage}`,
  getPasswordResetPage: (query?: Partial<PasswordResetPageQueryParams>) =>
    stringifyUrl({ url: `${paths.full}${paths.passwordResetPage}`, query: query || {} }),
  getPasswordSetPage: () => `${paths.full}${paths.passwordSetPage}`,
  getProfileVerifyPage: (query?: Partial<ProfileVerifyParams>) =>
    stringifyUrl({ url: `${paths.full}${paths.profileVerifyPage}`, query: query || {} }),
  getSuspiciousLoginPage: (query?: Partial<SuspiciousLoginValues>) =>
    stringifyUrl({ url: `${paths.full}${paths.suspiciousLoginPage}`, query: query || {} }),
};
