import { pathCreator } from 'barnyard/typescript/utils/urls';

export const basepath = '/auth';

export const createPaths = pathCreator(basepath);

const paths = createPaths('/', {
  basePage: '/',
  homePage: '/home',
  purchasingPortalPage: '/react',
  accountSignUp: '/accounts/signup',
});

export const externalUrls = {
  getBasePage: () => `${paths.basePage}`,
  getHomePage: () => `${paths.homePage}`,
  getPurchasingPortalPage: () => `${paths.purchasingPortalPage}`,
  // HD Host is set in base.html
  getPharmacyPortalPage: () => window.PHD_HOST,
  getAccountsSignUpPage: () => `${paths.accountSignUp}`,
};
