import React from 'react';
import { Router, RouteComponentProps } from '@reach/router';
import { Error404 } from 'barnyard/pages';

import LoadableRoute from 'barnyard/typescript/router/LoadableRoute';

import { paths } from './urls';

const AccountApp: React.FC<RouteComponentProps> = () => {
  return (
    <Router>
      <Error404 default={true} />
      <LoadableRoute
        importStatement={() => import('../components/pages/PortalPage')}
        path={paths.portalPage}
      />
      <LoadableRoute
        importStatement={() => import('../components/pages/AccountPage')}
        path={paths.accountPage}
      />
      <LoadableRoute
        importStatement={() => import('../components/pages/PasswordPage')}
        path={paths.passwordPage}
      />
      <LoadableRoute
        importStatement={() => import('../components/PasswordReset')}
        path={paths.passwordResetPage}
      />
      <LoadableRoute
        importStatement={() => import('../components/PasswordSet')}
        path={paths.passwordSetPage}
      />
      <LoadableRoute
        importStatement={() => import('../components/ProfileVerify')}
        path={paths.profileVerifyPage}
      />
      <LoadableRoute
        importStatement={() => import('../components/SuspiciousLogin')}
        path={paths.suspiciousLoginPage}
      />
    </Router>
  );
};

export default Object.assign(AccountApp, { paths });
