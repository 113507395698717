import { stringifyUrl } from 'query-string';

import { createPaths, basepath } from '~/auth/common/routes/urls';

export const paths = createPaths('signin', {
  signinPage: '/',
  signinFormPage: '/form',
  ssoSigninPage: '/sso',
});

export const urls = {
  getLoginPage: (query?: { next?: string; sso_client_id?: string }) =>
    stringifyUrl({ url: `${paths.full}`, query: query || {} }),
  getLoginFormPage: (query?: { next?: string; sso_client_id?: string }) =>
    stringifyUrl({ url: `${paths.full}${paths.signinFormPage}`, query: query || {} }),
  getSsoLoginPage: (query?: { next?: string; sso_client_id?: string }) =>
    stringifyUrl({ url: `${paths.full}${paths.ssoSigninPage}`, query: query || {} }),
};
